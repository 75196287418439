import './App.css';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>We are coming soon...</header>
    </div>
  );
}

export default App;
